<template>
    <vue-table-card class="m-3 c-table" title="Bulls List" :fields="fields" :url="listUrl"
                    :per-page="5" ref="table" :extra-params="{ status, species, breed, from, to}">
        <template #buttons>
            <drop-btn text="Filters" icon="fa fa-filter" size="sm" dropdown-width="40r">
                <div class="m-3 row">
                    <div class="col-6">
                        <validated-ajax-vue-select :url="breedOptions" v-model="breed" label="Breed" name="Breed"/>
                    </div>
                    <div class="col-6">
                        <validated-ajax-vue-select :url="speciesOptions" v-model="species" label="Species" name="Species"/>
                    </div>
                    <div class="col-6">
                        <validated-vue-select v-model="status" label="Status" name="Status" :options="statusOptions"/>
                    </div>
                    <div class="col-6">
                        <validated-vue-select v-model="damsYield" label="Dam's Yield" name="Dam's Yield" :options="yieldOptions"/>
                    </div>
                </div>
            </drop-btn>
            <btn icon="fa fa-plus" class="ml-1" size="sm" @click="showAddModal" text="Add"/>
        </template>
        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <edit-btn size="xs" class="btn-basic-b" @click="setEdit(rowData)"/>
                <delete-btn size="xs" @click="setDelete(rowData)"/>
                <btn icon="fa fa-eye" size="xs" class="btn-basic-b" @click="viewDetails(rowData)" text="Details"/>
            </div>
        </template>

        <template #footer>
            <modal no-close-on-backdrop title="Add Bull" ref="addModal" width="80r" header-color="primary">
                <s-form @submit="submitClicked('add')" ref="form">
                    <div>
                        <div class="row">
                            <div class="col">
                                <validated-input v-model="model.bullno" :rules="{required: false}"
                                                 label="Bull No" name="Bull No" :loading="loading" :disabled="loading"/>
                            </div>
                            <div class="col">
                                <validated-input v-model="model.name" :rules="{required: false}" label="Name"
                                                 name="Name" :loading="loading" :disabled="loading"/>
                            </div>
                            <div class="col">
                                <validated-file-input class="c-file-input" label="Bulls Image" name="Bulls Image"
                                                      v-model="model.file" :disabled="loading"/>
                            </div>
                            <div class="col">
                                <validated-ajax-vue-select :url="breedOptions" v-model="model.breed"
                                                           :rules="{required: false}"
                                                           label="Breed" name="Breed" :loading="loading"
                                                           :disabled="loading"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <validated-ajax-vue-select :url="speciesOptions" v-model="model.species"
                                                           :rules="{required: false}"
                                                           label="Species"
                                                           name="Species" :loading="loading" :disabled="loading"/>
                            </div>
                            <div class="col">
                                <validated-input v-model="model.inaph_code" :rules="{required: false}"
                                                 label="INAPH code"
                                                 name="INAPH code" :loading="loading" :disabled="loading"/>
                            </div>
                            <div class="col">
                                <validated-input v-model="model.uid" :rules="{required: false}"
                                                 label="UID"
                                                 name="UID" :loading="loading" :disabled="loading"/>
                            </div>
                            <div class="col">
                                <validated-date-picker v-model="model.dob" :rules="{required: false}" label="DOB"
                                                       name="DOB" :loading="loading" :disabled="loading"
                                                       :disabled-date="disabledBeforeTodayAndAfterAWeek"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <validated-input v-model="model.sirenumber" :rules="{required: false}"
                                                 label="Sire Number"
                                                 name="Sire Number" :loading="loading" :disabled="loading"/>
                            </div>
                            <div class="col">
                                <validated-input v-model="model.damnumber" :rules="{required: false}"
                                                 label="Dam's Number"
                                                 name="Dam's Number" :loading="loading" :disabled="loading"/>
                            </div>
                            <div class="col">
                                <validated-input v-model="model.dam_yeild" :rules="{required: false}" label="Dam's Yield"
                                                 name="Dam's Yield" :loading="loading" :disabled="loading"/>
                            </div>
                            <div class="col">
                                <validated-input v-model="model.sire_daughter_yield" :rules="{required: false}"
                                                 label="Sires Daughter's Yield"
                                                 name="Sires Daughter's Yield" :loading="loading" :disabled="loading"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <validated-input v-model="model.milkprotein" :rules="{required: false}"
                                                 label="Milk Protein"
                                                 name="Milk Protein" :loading="loading" :disabled="loading"/>
                            </div>
                            <div class="col">
                                <validated-input v-model="model.milkfat" :rules="{required: false}" label="Milk Fat"
                                                 name="Milk Fat" :loading="loading" :disabled="loading"/>
                            </div>
                            <div class="col">
                                <validated-input v-model="model.breeding_value" :rules="{required: false}"
                                                 label="Breeding Value"
                                                 name="Breeding Value" :loading="loading" :disabled="loading"/>
                            </div>
                            <div class="col">
                                <validated-input v-model="model.source" :rules="{required: false}" label="Source"
                                                 name="Source" :loading="loading" :disabled="loading"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-9">
                                <validated-vue-select v-model="model.district1" :rules="{required: false}"
                                                      label="Allotted District" name="Allotted District"
                                                      :loading="loading" :options="districtOptions"
                                                      :disabled="loading" multiple/>
                            </div>
                            <div class="col-3">
                                <validated-vue-select v-model="model.status" :rules="{required: false}"
                                                      label="Status" name="Status"
                                                      :loading="loading" :options="statusOptions"
                                                      :disabled="loading"/>
                            </div>
                        </div>
                    </div>
                    <btn size="sm" text="Save" :loading="loading" :disabled="loading" loading-text="Saving.."/>
                </s-form>
            </modal>

            <modal no-close-on-backdrop title="Edit Bull" ref="editModal" width="80r" header-color="primary">
                <s-form @submit="submitClicked('edit')" ref="form">
                    <div>
                        <div class="row">
                            <div class="col">
                                <validated-input v-model="model.bullno" :rules="{required: false}"
                                                 label="Bull No" name="Bull No" :loading="loading" :disabled="loading"/>
                            </div>
                            <div class="col">
                                <validated-input v-model="model.name" :rules="{required: false}" label="Name"
                                                 name="Name" :loading="loading" :disabled="loading"/>
                            </div>
                            <div class="col">
                                <validated-file-input class="c-file-input" label="Bulls Image" name="Bulls Image"
                                                      v-model="model.file" :disabled="loading"/>
                            </div>
                            <div class="col">
                                <validated-ajax-vue-select :url="breedOptions" v-model="model.breed"
                                                           :rules="{required: false}"
                                                           label="Breed" name="Breed" :loading="loading"
                                                           :disabled="loading"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <validated-ajax-vue-select :url="speciesOptions" v-model="model.species"
                                                           :rules="{required: false}"
                                                           label="Species"
                                                           name="Species" :loading="loading" :disabled="loading"/>
                            </div>
                            <div class="col">
                                <validated-input v-model="model.inaph_code" :rules="{required: false}"
                                                 label="INAPH code"
                                                 name="INAPH code" :loading="loading" :disabled="loading"/>
                            </div>
                            <div class="col">
                                <validated-input v-model="model.uid" :rules="{required: false}"
                                                 label="UID"
                                                 name="UID" :loading="loading" :disabled="loading"/>
                            </div>
                            <div class="col">
                                <validated-date-picker v-model="model.dob" :rules="{required: false}" label="DOB"
                                                       name="DOB" :loading="loading" :disabled="loading"
                                                       :disabled-date="disabledBeforeTodayAndAfterAWeek"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <validated-input v-model="model.sirenumber" :rules="{required: false}"
                                                 label="Sire Number"
                                                 name="Sire Number" :loading="loading" :disabled="loading"/>
                            </div>
                            <div class="col">
                                <validated-input v-model="model.damnumber" :rules="{required: false}"
                                                 label="Dam's Number"
                                                 name="Dam's Number" :loading="loading" :disabled="loading"/>
                            </div>
                            <div class="col">
                                <validated-input v-model="model.dam_yeild" :rules="{required: false}" label="Dam's Yield"
                                                 name="Dam's Yield" :loading="loading" :disabled="loading"/>
                            </div>
                            <div class="col">
                                <validated-input v-model="model.sire_daughter_yield" :rules="{required: false}"
                                                 label="Sires Daughter's Yield"
                                                 name="Sires Daughter's Yield" :loading="loading" :disabled="loading"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <validated-input v-model="model.milkprotein" :rules="{required: false}"
                                                 label="Milk Protein"
                                                 name="Milk Protein" :loading="loading" :disabled="loading"/>
                            </div>
                            <div class="col">
                                <validated-input v-model="model.milkfat" :rules="{required: false}" label="Milk Fat"
                                                 name="Milk Fat" :loading="loading" :disabled="loading"/>
                            </div>
                            <div class="col">
                                <validated-input v-model="model.breeding_value" :rules="{required: false}"
                                                 label="Breeding Value"
                                                 name="Breeding Value" :loading="loading" :disabled="loading"/>
                            </div>
                            <div class="col">
                                <validated-input v-model="model.source" :rules="{required: false}" label="Source"
                                                 name="Source" :loading="loading" :disabled="loading"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-9">
                                <validated-vue-select v-model="model.district1" :rules="{required: false}"
                                                      label="Allotted District" name="Allotted District"
                                                      :loading="loading" :options="districtOptions"
                                                      :disabled="loading" multiple/>
                            </div>
                            <div class="col-3">
                                <validated-vue-select v-model="model.status" :rules="{required: false}"
                                                      label="Status" name="Status"
                                                      :loading="loading" :options="statusOptions"
                                                      :disabled="loading"/>
                            </div>
                        </div>
                    </div>
                    <btn size="sm" text="Update" :loading="loading" :disabled="loading" loading-text="Updating.."/>
                </s-form>
            </modal>

            <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteSuccess">
                <p>You are about to delete the Bull. Are you sure?</p>
                <template #loading>
                    <loading-animation/>
                    Please wait while we delete the Bull.
                </template>
            </delete-modal>
        </template>

    </vue-table-card>
</template>

<script>
import urls from '@/data/urls';
import axios from 'secure-axios';

export default {
    name: 'BullsListing',
    data () {
        return {
            loading: false,
            status: '',
            damsYield: '',
            breed: '',
            from: '',
            to: '',
            species: '',
            listUrl: urls.admin.bulls.list,
            districtOptions: [
                { value: 'Alappuzha', label: 'Alappuzha' },
                { value: 'Ernakulam', label: 'Ernakulam' },
                { value: 'Idukki', label: 'Idukki' },
                { value: 'Kannur', label: 'Kannur' },
                { value: 'Kasaragod', label: 'Kasaragod' },
                { value: 'Kollam', label: 'Kollam' },
                { value: 'Kottayam', label: 'Kottayam' },
                { value: 'Kozhikode', label: 'Kozhikode' },
                { value: 'Malappuram', label: 'Malappuram' },
                { value: 'Palakkad', label: 'Palakkad' },
                { value: 'Pathanamthitta', label: 'Pathanamthitta' },
                { value: 'Thiruvananthapuram', label: 'Thiruvananthapuram' },
                { value: 'Thrissur', label: 'Thrissur' },
                { value: 'Wayanad', label: 'Wayanad' }
            ],
            statusOptions: [
                { value: 'published', label: 'Published' },
                { value: 'unpublished', label: 'UnPublished' }
            ],
            yieldOptions: [
                { value: 1, label: '2000 - 4000' },
                { value: 2, label: '4000 - 6000' },
                { value: 3, label: '6000 - 8000' },
                { value: 4, label: '8000 - 10000' },
                { value: 5, label: '10000 and above' }
            ],
            speciesOptions: urls.vueSelect.speciesOptions,
            breedOptions: urls.vueSelect.breedOptions,
            deleteUrl: urls.admin.bulls.delete,
            addUrl: urls.admin.bulls.add,
            editUrl: urls.admin.bulls.edit,
            deletingItem: {
                id: ''
            },
            fields: [
                {
                    name: 'slno',
                    title: 'No'
                },
                {
                    name: 'bullno',
                    title: 'Bull No'
                },
                {
                    name: 'name',
                    title: 'Bull Name'
                },
                {
                    name: 'species.name',
                    title: 'Species'
                },
                {
                    name: 'breed.name',
                    title: 'Breeds'
                },
                {
                    name: 'dam_yeild',
                    title: 'Yield'
                },
                {
                    name: 'status',
                    title: 'Status'
                },
                {
                    name: '__slot:actions',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right',
                    title: 'Actions'
                }
            ],
            model: {}
        };
    },
    watch: {
        damsYield: {
            handler () {
                this.changeRange();
            }
        }
    },
    methods: {
        changeRange () {
            if (this.damsYield === 1) {
                this.from = 2000;
                this.to = 4000;
            } else if (this.damsYield === 2) {
                this.from = 4000;
                this.to = 6000;
            } else if (this.damsYield === 3) {
                this.from = 6000;
                this.to = 8000;
            } else if (this.damsYield === 4) {
                this.from = 8000;
                this.to = 10000;
            } else {
                this.from = 10000;
                this.to = 10000000;
            }
        },
        disabledBeforeTodayAndAfterAWeek (date) {
            const today = new Date();
            return date > today;
        },
        viewDetails (item) {
            this.$router.push('/bull/' + item.id + '/details/');
        },
        showAddModal () {
            this.model = {};
            this.$refs.addModal.show();
        },
        setEdit (item) {
            this.model = { ...item };
            if (this.model.breed) {
                this.model.breed = this.model.breed.id;
            }
            if (this.model.species) {
                this.model.species = this.model.species.id;
            }
            if (this.model.district) {
                this.model.district1 = this.model.district.split(',');
            }
            this.$refs.editModal.show();
        },
        setDelete (item) {
            this.deletingItem.id = item.id;
            this.$refs.deleteModal.show();
        },
        deleteSuccess () {
            this.$refs.deleteModal.close();
            this.deletingItem.id = '';
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        removeNull () {
            for (const propName in this.model) {
                if (this.model[propName] === null || this.model[propName] === undefined) {
                    this.model[propName] = '';
                }
            }
        },
        async submitClicked (action) {
            this.removeNull();
            if (this.model.district1) {
                this.model.district = this.model.district1.toString();
            }
            const that = this;
            that.loading = true;
            if (action === 'add') {
                that.action = that.addUrl;
            }
            if (action === 'edit') {
                that.action = that.editUrl;
            }
            const response = await axios.form(that.action, that.model);
            const json = response.data;
            if (json.error === false) {
                if (action === 'add') {
                    that.formSuccess(response);
                }
                if (action === 'edit') {
                    that.formSuccess1(response);
                }
                that.loading = false;
            } else {
                that.formError(json);
                that.loading = false;
            }
            that.loading = false;
        },
        formSuccess (response) {
            const data = response.data;
            if (data.district) {
                this.model.district1 = data.district.split(',');
            }
            this.$refs.addModal.close();
            this.$refs.editModal.close();
            this.$notify('Bull Saved Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        formSuccess1 (response) {
            const data = response.data;
            if (data.district) {
                this.model.district1 = data.district.split(',');
            }
            this.$refs.addModal.close();
            this.$refs.editModal.close();
            this.$notify('Bull Updated Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        formError (json) {
            this.$refs.form.setErrors(json.errors);
        }
    }
};
</script>

<style scoped>

</style>
